import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = JSON.parse(`${process.env.GATSBY_FIREBASE_CONFIG}`);

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
